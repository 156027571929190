import SignUp from "../authModule/components/SignUp/SignUp";


function SignUpPage() {
  return (
      <SignUp />
  );
}


export default SignUpPage;