// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row:hover>* {
    /* color: blue; */
    cursor: pointer;
    background-color: rgb(224, 224, 224);
}

.head {
    background-color: #46b077;
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/SalesmanModule/components/ClientList/clientList.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".row:hover>* {\n    /* color: blue; */\n    cursor: pointer;\n    background-color: rgb(224, 224, 224);\n}\n\n.head {\n    background-color: #46b077;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
