// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account {
    margin-inline: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin: auto;
    justify-content: space-evenly;
}

.command-area {
    grid-column: span 2;
}

.account>* {
    /* flex-grow: 1; */
    width: 100%;
    padding: 50px 10px;
}`, "",{"version":3,"sources":["webpack://./src/accountModule/components/Account/account.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,qCAAqC;IACrC,aAAa;IACb,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".account {\n    margin-inline: auto;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-gap: 5px;\n    margin: auto;\n    justify-content: space-evenly;\n}\n\n.command-area {\n    grid-column: span 2;\n}\n\n.account>* {\n    /* flex-grow: 1; */\n    width: 100%;\n    padding: 50px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
