import LogIn from "../authModule/components/Login/Login";


function LoginPage() {
  return (
      <LogIn />
  );
}


export default LoginPage;