// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head {
    background-color: #46b077;
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/orderModule/components/OrdersStatus/orderStatus.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".head {\n    background-color: #46b077;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
